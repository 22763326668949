import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function Reviews() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Reviews">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Reviews"
                    Subheader="Reviews"
                    bgimg={`${rpdata?.stock?.[0]}`}
                />
            </div>
            <div className="text-center py-[100px]">
                <h1 className="pb-10"> Our Reviews</h1>
                {/* <div className="elfsight-app-396b2fee-055d-4a1d-8e96-c2667f9a61e3"></div> */}
            </div>
        </BaseLayout>
    );
}

export default Reviews;
